/**
 * ACF field input masking
 *
 * @since 1.0.0
 * @package grocery
 */

var $ = jQuery;

var GroceryACFInputmask = {

	/**
	 * Numeric inputs (with commas)
	 *
	 * @var jQuery object
	 * @since 1.0.0
	 */
	inputsNumeric : {},


	/**
	 * Spin up stuff
	 *
	 * @since 1.0.0
	 */
	init : function() {

		// sanity check
		if ( ! ( 'inputmask' in $.fn ) ) {
			return;
		}

		// comma'd numbers
		$( '.inputmask-number' ).inputmask({
			alias          : 'numeric',
			groupSeparator : ',',
			groupSize      : 3,
			autoGroup      : true,
			rightAlign     : false,
		});

		// phone
		$( '.inputmask-phone' ).inputmask( '(999) 999-9999' );

		// year
		$( '.inputmask-year' ).inputmask({
			alias      : 'numeric',
			mask       : '9999',
			rightAlign : false,
		});
	},
};

$( document ).ready( GroceryACFInputmask.init );
