/**
 * Deal interactions
 *
 * @since 1.0.0
 * @package grocery
 */

var $ = jQuery;

var GroceryDeal = {

	/**
	 * The documents this deal interacts with
	 *
	 * @var object
	 * @since 1.0.0
	 */
	documents : {},

	/**
	 * Spin up the deal stuff
	 *
	 * @since 1.0.0
	 */
	init : function() {

		// sanity check
		if ( groceryAnchored.post.type !== 'ga_deal' || !groceryAnchored.is_single ) {
			return;
		}

		GroceryDeal.initTabs();
		GroceryDeal.initAccordion();
		GroceryDeal.initDocuments();
		GroceryDeal.initReviewButtons();
		GroceryDeal.maybeOpenCAModal();
	},


	/**
	 * Init the tabs
	 *
	 * @since 1.0.0
	 */
	initTabs : function() {
		$( '#deal-tabs').tabs({ heightStyle : 'content' });
	},


	/**
	 * Init the communications accordion
	 *
	 * @since 1.0.0
	 */
	initAccordion : function() {

		// see if we have multiple people
		var $comms   = $( '#tab-communications > div' );
		var $headers = $( 'header', $comms );

		if ( $headers.length < 2 ) {
			return;
		}

		// SPINSIES
		$comms.accordion({
			collapsible		: true,
			active			: false,
			header			: 'header',
			heightStyle		: 'content',
			beforeActivate	: GroceryDeal.checkAccordionHeaderClick,
		});
	},


	/**
	 * Force an anchor's click to href, if that's what was clicked
	 *
	 * @param event  e  click event
	 * @since 1.0.0
	 */
	checkAccordionHeaderClick : function name( e ) {

		var $toElement = $( e.toElement );

		// if an icon wasn't clicked, bounce
		if ( ! $toElement.hasClass( 'fa' ) ) {
			return;
		}

		// go to its parent's href (like an email link)
		window.location.href = $toElement.parent( 'a' )[0].href;
	},


	/**
	 * Grab and bind our documents
	 *
	 * @since 1.0.0
	 */
	initDocuments : function() {

		var get = GroceryDeal.getDocument;

		GroceryDeal.documents = {
			buttons	: $( '[data-document]' ),
			summary	: get( 'executive_summary' ),
			ca		: get( 'ca' ),
			om		: get( 'om' ),
			loi		: get( 'loi' ),
		};

		GroceryDeal.documents.buttons.on( 'click', GroceryDeal.checkLockMaybeUnlock );
	},


	/**
	 * Get a document selector
	 *
	 * @since 1.0.0
	 */
	getDocument : function( document ) {
		return $( '[data-document="' + document  + '"]' );
	},


	/**
	 * Maybe unlock a button based on the clicked button and its state
	 *
	 * @since 1.0.0
	 */
	checkLockMaybeUnlock : function( e ) {

		var $this = $( this );

		// check if the button clicked is even unlocked
		var isLocked = $this.hasClass( 'disabled' );

		// if the button is locked, cancel click and bounce
		if ( isLocked ) {
			e.preventDefault();
			return;
		}

		// what document/title are we unlocking?
		var unlock = $this.data( 'unlocks' );

		// find the button TO unlock and unlock it
		GroceryDeal.maybeUnlockButton( unlock );
		GroceryDeal.maybeUnlockTitle( unlock );
	},


	/**
	 * Maybe unlock a button
	 *
	 * @param string  document  document to unlock
	 * @since 1.0.0
	 */
	maybeUnlockButton : function( document ) {

		if ( ! document ) {
			return;
		}

		GroceryDeal.documents[ document ].removeClass( 'disabled' );
	},


	/**
	 * Maybe swap a locked document's title
	 *
	 * @param string  document  title's document
	 * @since 1.0.0
	 */
	maybeUnlockTitle : function( document ) {

		var $title = $( '[data-document-title="' + document + '"]' );

		if ( ! $title ) {
			return;
		}

		$title.text( $title.data( 'unlocked-title' ) );
	},


	/**
	 * Set up/bind the review buttons
	 *
	 * @since 1.0.0
	 */
	initReviewButtons : function() {
		var reviewButtons = $( '[role="button"][data-review]' );
		reviewButtons.on( 'click', GroceryDeal.addEsignReview );
	},


	/**
	 * Add a review for an esigned document
	 *
	 * @param event  e  click event
	 * @since 1.0.0
	 */
	addEsignReview : function() {

		var $this = $(this);

		// get esig ID, review action, and the other review buttons
		var esig_id = $this.data( 'esig-id' );
		var review  = $this.data( 'review' );
		var buttons = $( 'button[data-esig-id="' + esig_id + '"]' );

		// disable the buttons and add a "loading" class to parent
		buttons.prop( 'disabled', 'disabled' );
		buttons.addClass( 'disabled' );
		GroceryDeal.toggleDocumentWorking( esig_id );

		// send the activity
		GroceryActivities.doActivity( 'esign_review', {
			esig_id	: esig_id,
			review	: review,
		}, GroceryDeal.removeReviewButtons );
	},


	/**
	 * Remove a document's review buttons, keeping a placeholder for what the
	 * user chose
	 *
	 * @param object  response  what happened with the esign (ajax)
	 * @since 1.0.0
	 */
	removeReviewButtons : function( response ) {

		// sanity check error
		if ( ! response.success ) {
			return;
		}

		// get review and esig ID
		var review  = response.data.review;
		var esig_id = response.data.esig_id;

		// remove the button(s) that weren't picked for the document
		$( 'button[data-esig-id="' + esig_id + '"]' ).not( '[data-review="' + review + '"]' ).remove();

		// toggle working class
		GroceryDeal.toggleDocumentWorking( esig_id );
	},


	/**
	 * Toggle an esig document's parent "working" class
	 *
	 * @param integer  esig_id  esignature id
	 * @since 1.0.0
	 */
	toggleDocumentWorking : function( esig_id ) {
		// $( '.document-actions[data-esig-id="' + esig_id + '"]' ).toggleClass( 'working' );
		$( '.document-actions[data-esig-id="' + esig_id + '"] .working' ).fadeToggle();
	},


	/**
	 * Maybe open the confidentiality agreement modal
	 *
	 * @since 1.0.0
	 */
	maybeOpenCAModal : function() {

		var $modal = $( '#ca-modal' );

		// if no modal or registration error, bounce
		if ( $modal.length < 1 || ( ! groceryAnchored.guest_registration_error && ! $modal.hasClass( 'open' ) ) ) {
			return;
		}

		setTimeout( function() {
			$.featherlight( $modal );
		}, 100 );
	}
};

// spin up the deal
$( document ).ready( GroceryDeal.init );
