/**
 * Gallery slider
 *
 * @since 1.0.0
 * @package grocery
 */

(function($) {
	$(document).ready(function() {

		// sanity check flickity
		if ( ! ( 'flickity' in $.fn ) ) {
			return;
		}

		var slider = '.gallery-slider .slides';
		var nav    = '.gallery-slider nav';

		$( slider ).flickity({
			prevNextButtons : false,
			pageDots        : false,
		});

		$( nav ).flickity({
			asNavFor : slider,
			contain  : true,
			pageDots : false,
		});

	}); // document.ready
})(jQuery);
