/**
 * Deal creation/editing interactions
 *
 * @since 1.0.0
 * @package grocery
 */

var $ = jQuery;

var GroceryDealEdit = {

	/**
	 * Spin up the deal stuff
	 *
	 * @since 1.0.0
	 */
	init : function() {

		// sanity check
		if ( ! $( 'body' ).hasClass( 'deal-edit' ) ) {
			return;
		}

		GroceryDealEdit.initTooltips();
	},


	/**
	 * Init the tooltips
	 *
	 * @since 1.0.0
	 */
	initTooltips : function() {

		$( '.tooltip' ).tooltip({
			position : {
				at : "left top-5",
				my : "left bottom",
			}
		});
	},
};

// spin up the deal
$( document ).ready( GroceryDealEdit.init );
