/**
 * Grocery activity JS handler
 *
 * @since 1.0.0
 * @package grocery
 */

var $ = jQuery;

var GroceryActivities = {

	/**
	 * Do an activity action
	 *
	 * @param string    action   the action we're trying to do
	 * @param object    details  whatever data is needed for the action
	 * @param callback  done     what to execute after a successful response
	 *
	 * @since 1.0.0
	 */
	doActivity : function( activity, details, done ) {

		activity = groceryAnchored.activities[ activity ];

		// configure request
		$.ajax({
			url     : groceryAnchored.ajax_url,
			type    : 'post',
			data    : {
				action  : groceryAnchored.action_prefix + activity.action,
				nonce   : activity.nonce,
				details : details,
				post_id : groceryAnchored.post.id,
			},
			success : done,
		});
	},
};
