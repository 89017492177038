/**
 * Grocery filters JS handler
 *
 * @since 1.0.0
 * @package grocery
 */

var $ = jQuery;

var GroceryFilters = {

	/**
	 * The search form
	 *
	 * @var jQuery object
	 * @since 1.0.0
	 */
	searchForm : {},

	/**
	 * The filter form
	 *
	 * @var jQuery object
	 * @since 1.0.0
	 */
	filterForm : {},

	/**
	 * The filter header
	 *
	 * @var jQuery object
	 * @since 1.0.0
	 */
	filterHeader : {},

	/**
	 * The main element
	 *
	 * @var jQuery object
	 * @since 1.0.0
	 */
	mainElement : {},

	/**
	 * The filter query string
	 *
	 * @var string
	 * @since 1.0.0
	 */
	filterQueryString : '',

	/**
	 * The filter query string
	 *
	 * @var string
	 * @since 1.0.0
	 */
	rangeFilterMaxSubstitute : '987987987',

	/**
	 * Class init
	 *
	 * @since 1.0.0
	 */
	init : function() {

		// Initialize for home page search form
		if ( $('body.home.page #deal-search')[0] ) {
			GroceryFilters.initHomepageSearch();
		}

		// Initialize for deal search / filters page
		if ( groceryAnchored.filters.is_deal_search ) {
			GroceryFilters.searchForm   = $( 'form#deal-search'         );
			GroceryFilters.filterForm   = $( 'form#deal-search-filters' );
			GroceryFilters.filterHeader = $( 'header#filters'           );
			GroceryFilters.mainElement  = $( 'main', '#content'         );

			GroceryFilters.initFilters();
			GroceryFilters.initAccordion();
			GroceryFilters.initMasks();
		}
	},


	/**
	 * Homepage search init
	 *
	 * @since 1.0.0
	 */
	initHomepageSearch : function() {

		// Make it so that submitting an empty search field won't add the 's'
		// query var
		$( '#deal-search' ).on( 'submit', function( e ) {
			$( this ).find( 'input' ).filter( function( i ) {
				return !this.value;
			}).prop( 'name', '' );
		});
	},


	/**
	 * Filters init
	 *
	 * @since 1.0.0
	 */
	initFilters : function() {

		var $toggleFiltersButton = $( 'h3'                   , GroceryFilters.filterHeader );
		var $searchSubmit        = $( 'button[type="submit"]', GroceryFilters.searchForm   );
		var $filterSubmit        = $( 'button.filter-submit' , GroceryFilters.filterForm   );
		var $filterClear         = $( 'button.filter-clear'                                );
		var $window              = $( window                                               );

		$toggleFiltersButton.on( 'click', GroceryFilters.toggleFilters );
		$searchSubmit.on( 'click', GroceryFilters.applyFilters );
		$filterSubmit.on( 'click', GroceryFilters.applyFilters );
		$filterClear.on( 'click', GroceryFilters.clearFilters );

		// Detect click outside of filters
		$window.on('click', function( e ) {
			if ( GroceryFilters.filterForm.hasClass( 'open' ) ) {
				if (
					GroceryFilters.filterForm.has( e.target ).length == 0 // Something inside form was clicked
					&&
					!GroceryFilters.filterForm.is( e.target ) // Form itself was clicked
				){
					e.preventDefault();

					GroceryFilters.closeFilters();
				}
			}
		});
	},


	/**
	 * Accordion init
	 *
	 * @since 1.0.0
	 */
	initAccordion : function() {

		var $filterItems = $( '> ul', GroceryFilters.filterForm );

		$filterItems.accordion({
			collapsible : true,
			active      : 0,
			header      : 'header',
			heightStyle : 'content'
		});
	},


	/**
	 * Masks init
	 *
	 * @since 1.0.0
	 */
	initMasks : function() {

		$( ':input', GroceryFilters.filterForm ).inputmask({'oncleared': GroceryFilters.maskCleared});
	},


	/**
	 * Masks on cleared
	 *
	 * @param  event e the input's clear event
	 * @since 1.0.0
	 */
	maskCleared : function( e ) {

		var $target = $(e.currentTarget);

		$target.inputmask('setvalue', '');
	},


	/**
	 * Toggle the filters form
	 *
	 * @since 1.0.0
	 */
	toggleFilters : function() {

		GroceryFilters.filterForm.slideToggle( {
			complete : function() {
				GroceryFilters.filterForm.toggleClass( 'open' );
			},
			step : GroceryFilters.expandMainToFitFilterForm
		});
	},


	/**
	 * Expand the main element to fit the filter form
	 *
	 * @since 1.0.0
	 */
	expandMainToFitFilterForm : function() {

		var $filterFormBottom = GroceryFilters.filterForm.offset().top + GroceryFilters.filterForm.outerHeight( true );
		var $mainBottom = GroceryFilters.mainElement.position().top + GroceryFilters.mainElement.outerHeight( true );

		if ( $filterFormBottom > $mainBottom ) {
			var $mainCurrentCssHeight = parseInt( GroceryFilters.mainElement.css( 'height' ) );
			var $mainNewCssHeight = $mainCurrentCssHeight + ( $filterFormBottom - $mainBottom );

			GroceryFilters.mainElement.css( 'height', $mainNewCssHeight );
		}
	},


	/**
	 * Close the filters form
	 *
	 * @since 1.0.0
	 */
	closeFilters : function() {

		GroceryFilters.filterForm.slideUp( {
			complete : function() {
				GroceryFilters.filterForm.removeClass( 'open' );
			}
		});
	},


	/**
	 * Apply filters
	 *
	 * @param  event e the button's click event
	 * @since 1.0.0
	 */
	applyFilters : function( e ) {

		e.preventDefault();

		GroceryFilters.applyOverlay();

		GroceryFilters.validateFilters();

		var $searchQuery = GroceryFilters.getSerializedForm( GroceryFilters.searchForm );
		var $filterQuery = GroceryFilters.getFilterQueryString();

		var $newSearchLocation = GroceryFilters.searchForm.attr( 'action' ) + '?';
		$newSearchLocation += ( $searchQuery.length > 0 ? $searchQuery + '&' + $filterQuery : $filterQuery );

		window.location.href = $newSearchLocation;
	},


	/**
	 * Validate filters
	 *
	 * @since 1.0.0
	 */
	validateFilters : function() {

		GroceryFilters.filterQueryString = '';

		$( '.filter-section' ).each( function( $index ) {
			GroceryFilters.validateFilterByType( $( this ) );
		});
	},


	/**
	 * Validate filter by type
	 *
	 * @param  jQuery object $section the jQuery object containing filters
	 * @since 1.0.0
	 */
	validateFilterByType : function( $section ) {

		var $filterType = $section.attr('data-filter-type');
		var $validationFunction = 'validate' + $filterType.charAt( 0 ).toUpperCase() + $filterType.slice( 1 ) + 'Filter';

		GroceryFilters[ $validationFunction ]( $section );
	},


	/**
	 * Validation for range filters
	 *
	 * @param  jQuery object $section the jQuery object containing filters
	 * @since 1.0.0
	 */
	validateRangeFilter : function( $section ) {

		var $inputs = $section.find( 'input' );

		// Clear out any non-numeric values
		$inputs.each( function( $index ) {
			if ( isNaN( $($inputs[ $index ]).inputmask('unmaskedvalue') ) ) {
				$inputs[ $index ].value = '';
			}
		});

		var $first_input = $($inputs[0]);
		var $first_input_name = $first_input.attr('name');
		var $first_input_value = $first_input.inputmask('unmaskedvalue');

		var $second_input = $($inputs[1]);
		var $second_input_name = $second_input.attr('name');
		var $second_input_value = $second_input.inputmask('unmaskedvalue');

		if ( $first_input_value == '' && $second_input_value == '' ) {

			// Both inputs are empty
			return;
		}

		var $prefix = ( GroceryFilters.filterQueryString == '' ? '' : '&' );

		if ( $first_input_value == '' && $second_input_value != '' ) {

			// The first input value is empty
			$first_input.inputmask('setvalue', '0');

			GroceryFilters.filterQueryString += $prefix + $first_input_name + '=0';
			GroceryFilters.filterQueryString += '&' + $second_input_name + '=' + $second_input_value;

			return;
		}

		if ( $first_input_value != '' && $second_input_value == '' ) {

			// The second input value is empty
			GroceryFilters.filterQueryString += $prefix + $first_input_name + '=' + $first_input_value;
			GroceryFilters.filterQueryString += '&' + $second_input_name + '=' + GroceryFilters.rangeFilterMaxSubstitute;

			return;
		}

		if ( $first_input_value != '' && $second_input_value != '' ) {

			// Neither are empty
			GroceryFilters.filterQueryString += $prefix + $first_input_name + '=' + $first_input_value;
			GroceryFilters.filterQueryString += '&' + $second_input_name + '=' + $second_input_value;

			return;
		}
	},


	/**
	 * Clear the filters
	 *
	 * @param  event e the button's click event
	 * @since 1.0.0
	 */
	clearFilters : function( e ) {

		e.preventDefault();

		GroceryFilters.applyOverlay();

		$( ':input', GroceryFilters.filterForm ).val( '' );
		$( '.vis-filter-box' ).remove();

		var $searchQuery = GroceryFilters.getSerializedForm( GroceryFilters.searchForm );

		var $newSearchLocation = GroceryFilters.searchForm.attr( 'action' );
		$newSearchLocation += ( $searchQuery.length > 0 ? '?' + $searchQuery + '&': '' );

		window.location.href = $newSearchLocation;
	},


	/**
	 * Apply the ajax overlay
	 *
	 * @since 1.0.0
	 */
	applyOverlay : function() {

		$( '#content' ).addClass( 'ajax-overlay' );
	},


	/**
	 * Helper function to get the filter query string
	 *
	 * @return string URL encoded query string
	 * @since 1.0.0
	 */
	getFilterQueryString : function() {

		return encodeURI( GroceryFilters.filterQueryString );
	},


	/**
	 * Helper function to get a form's values as a serialized array
	 *
	 * @param  jQuery object $form the form object
	 *
	 * @return string              the serialized values
	 * @since 1.0.0
	 */
	getSerializedForm : function( $form ) {

		return $form.find( ':input' ).filter(function () {
		    return $.trim( this.value ).length > 0;
		}).serialize();
	}
};

// spin up chickens
$( document ).ready( GroceryFilters.init );
