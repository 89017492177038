/**
 * Activity notice modals
 *
 * @since 1.0.0
 * @package grocery
 */

(function($) {
	$(document).ready(function() {

		var $notices = $( '.activity-notice' );

		// sanity check featherlight & notices
		if ( $notices.length < 1 || ! ( 'featherlight' in $.fn ) ) {
			return;
		}

		setTimeout( function() {
			$.featherlight( $notices );
		}, 250 );

	}); // document.ready
})(jQuery);
